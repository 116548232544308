import { Typography } from '@mui/material';
import ColoredButton from 'components/Buttons/ColoredButton';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { SActionsContainer, SContainer } from './EntityNotFound.styled';
import { entityNotFoundContents } from './utils';

export enum EntityNotFoundType {
    VacancyProfile = 'vacancyProfile',
    TalentProfile = 'talentProfile',
}
interface Props {
    entityType: EntityNotFoundType;
}

const EntityNotFound: React.FC<Props> = ({ entityType }) => {
    const { push } = useRouter();

    const { title, imgSrc, helpingText, url, btnTitle } =
        entityNotFoundContents[entityType];

    const onButtonClick = () => {
        push(url);
    };

    return (
        <SContainer alignItems="center">
            <Typography variant="h5_headline">{title}</Typography>
            <Image
                alt="Not found"
                src={imgSrc}
                width={140}
                height={140}
                objectFit="contain"
            />
            <SActionsContainer alignItems="center">
                <Typography variant="body_1">{helpingText}</Typography>
                <ColoredButton
                    title={btnTitle}
                    color="blue"
                    handler={onButtonClick}
                />
            </SActionsContainer>
        </SContainer>
    );
};

export default EntityNotFound;
